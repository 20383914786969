<!-- Template -->
<template>
  <layout-div>
    <div>
      <div class="content-container">
        <NavBarComponent @logout="logoutAction" />
      </div>
      <br />
      <div class="d-flex justify-content-center mt-5">
        <button class="btn btn-primary" @click="showAbsenceModal = true">
          Abwesenheit melden
        </button>
      </div>

      <div class="mt-10 d-flex justify-content-center">
        <div class="w-20 max-w-2xl">
          <div class="table-responsive">
            <table
              class="table table-bordered table-hover bg-white rounded shadow w-100"
            >
              <thead>
                <tr class="bg-light">
                  <th class="text-center">Saldo Urlaub</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center fw-bold">
                    {{ this.user.vacation }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="content-container mt-3">
        <div v-if="userAbsences.length > 0" class="mt-5 px-4">
          <h2 class="fs-4 fw-bold mb-4">Meine Abwesenheiten</h2>
          <div class="legend mb-3">
            <div class="legend-item me-3">
              <span class="legend-color bg-info"></span>
              <span>Abw. noch nicht freigegeben</span>
            </div>
            <div class="legend-item">
              <span class="legend-color bg-danger"></span>
              <span>Dokumente nicht hochgeladen</span>
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-hover bg-white rounded shadow w-100"
            >
              <thead>
                <tr class="bg-light">
                  <th>Status</th>
                  <th>Grund</th>
                  <th>Von</th>
                  <th>Bis</th>
                  <th>Kommentar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(absence, index) in userAbsences"
                  :key="index"
                  @click="openUploadModal(absence)"
                  class="cursor-pointer"
                  :class="{
                    'table-danger':
                      absence.admin_absence_confirmed === true &&
                      (absence.sickConfirmationFileId === null ||
                        absence.healthConfirmationFileId === null),
                    'table-info': absence.admin_absence_confirmed === false,
                    'table-light':
                      index % 2 === 0 &&
                      absence.admin_absence_confirmed === true &&
                      absence.sickConfirmationFileId !== null &&
                      absence.healthConfirmationFileId !== null,
                    'bg-white':
                      index % 2 !== 0 &&
                      absence.admin_absence_confirmed === true &&
                      absence.sickConfirmationFileId !== null &&
                      absence.healthConfirmationFileId !== null,
                  }"
                >
                  <td>
                    <span
                      v-if="
                        absence.sickConfirmationFileId !== null &&
                        absence.sickConfirmationFileId !== undefined
                      "
                      class="badge bg-success me-1"
                    >
                      Abwesend.Doc <i class="bi bi-check-circle"></i>
                    </span>
                    <span
                      v-if="
                        absence.healthConfirmationFileId !== null &&
                        absence.healthConfirmationFileId !== undefined
                      "
                      class="badge bg-success me-1"
                    >
                      Anwesend.Doc <i class="bi bi-check-circle"></i>
                    </span>
                    <span
                      v-if="
                        absence.sickConfirmationFileId === null &&
                        absence.healthConfirmationFileId === null
                      "
                      class="badge bg-danger"
                    >
                      <i
                        class="bi bi-info-circle-fill ms-1"
                        title="Bitte laden Sie die erforderlichen Dokumente hoch"
                      ></i>
                      Dokument hochladen
                    </span>
                  </td>
                  <td>{{ absence.absence_type }}</td>
                  <td>
                    {{ formatDate(absence.absence_start) }} -
                    {{ absence.absence_time_start }}
                  </td>
                  <td>
                    {{ formatDate(absence.absence_end) }} -
                    {{ absence.absence_time_end }}
                  </td>
                  <td>{{ absence.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="content-container mt-3">
        <div v-if="absencesChildren.length > 0" class="mt-5 px-4">
          <h2 class="fs-4 fw-bold mb-4">Betreuungs-Kind Abwesenheit</h2>
          <div class="table-responsive">
            <table
              class="table table-bordered table-hover bg-white rounded shadow w-100"
            >
              <tbody>
                <tr v-for="(absence, index) in absencesChildren" :key="index">
                  <td>
                    {{ getChildName(absence.fk_children_id) }}
                  </td>
                  <td>Abwesend ab {{ formatDate(absence.absence_start) }}</td>
                  <td>{{ absence.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Absence Modal -->
      <div
        v-if="showAbsenceModal"
        class="modal fade show"
        style="display: block"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Abwesenheit melden</h5>
              <button
                type="button"
                class="btn-close"
                @click="showAbsenceModal = false"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="absenceType" class="form-label"
                  >Wer ist abwesend?</label
                >
                <select
                  id="absenceType"
                  v-model="absenceType"
                  class="form-select"
                >
                  <option value="">Bitte wählen</option>
                  <option value="caregiver">Mich Abwesend melden</option>
                  <option value="child">Kind Abwesend</option>
                </select>
              </div>

              <div class="mb-3" v-if="absenceType === 'child'">
                <label for="childSelect" class="form-label"
                  >Kind auswählen</label
                >
                <select
                  id="childSelect"
                  v-model="selectedChild"
                  class="form-select"
                >
                  <option value="">Bitte Kind auswählen</option>
                  <option
                    v-for="(child, index) in children"
                    :key="index"
                    :value="child"
                  >
                    {{ child }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="absenceDateStart" class="form-label"
                  >Abwesenheit-Start</label
                >
                <input
                  type="date"
                  v-model="absenceDateStart"
                  id="absenceDateStart"
                  class="form-control"
                />
              </div>

              <template v-if="absenceType !== 'child'">
                <div class="mb-3">
                  <label for="absenceDateEnd" class="form-label"
                    >Abwesenheit-Ende</label
                  >
                  <input
                    type="date"
                    v-model="absenceDateEnd"
                    id="absenceDateEnd"
                    class="form-control"
                  />
                </div>

                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    id="allDay"
                    v-model="allDay"
                    @change="setAllDayTimes"
                    class="form-check-input"
                  />
                  <label for="allDay" class="form-check-label">Ganztägig</label>
                </div>

                <div class="mb-3" v-if="!allDay">
                  <label for="startTime" class="form-label"
                    >Uhrzeit-Start</label
                  >
                  <select
                    id="startTime"
                    v-model="startTime"
                    class="form-select"
                  >
                    <option
                      v-for="time in timeOptions"
                      :key="time"
                      :value="time"
                    >
                      {{ convertToHHMM(time) }}
                    </option>
                  </select>
                </div>

                <div class="mb-3" v-if="!allDay">
                  <label for="endTime" class="form-label">Uhrzeit-Ende</label>
                  <select id="endTime" v-model="endTime" class="form-select">
                    <option
                      v-for="time in timeOptions"
                      :key="time"
                      :value="time"
                    >
                      {{ convertToHHMM(time) }}
                    </option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="absenceReason" class="form-label"
                    >Grund der Abwesenheit</label
                  >
                  <select
                    id="absenceReason"
                    v-model="absenceReason"
                    class="form-select"
                  >
                    <option value="Krankenstand">Krankenstand</option>
                    <option value="Arzttermin">Arzttermin</option>
                    <option value="Zeitausgleich">Zeitausgleich</option>
                    <option value="Pflegeurlaub">Pflegeurlaub</option>
                    <option value="Sonderurlaub">Sonderurlaub</option>
                    <option value="Urlaub">Urlaub</option>
                    <option value="Sonstiges">Sonstiges</option>
                  </select>
                </div>
              </template>

              <div class="mb-3">
                <label for="comment" class="form-label">Kommentar</label>
                <textarea
                  id="comment"
                  v-model="comment"
                  rows="3"
                  placeholder="Optional: Kommentar hinzufügen (max. 500 Zeichen)"
                  class="form-control"
                  maxlength="500"
                  @input="checkCommentLength"
                ></textarea>
                <small class="text-muted"
                  >{{ comment.length }}/500 Zeichen</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                @click="showAbsenceModal = false"
              >
                Schließen
              </button>
              <button
                class="btn btn-primary"
                @click="reportAbsence"
                :disabled="!isValidForm"
              >
                Abwesenheit melden
              </button>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>

      <!-- Upload Modal -->
      <div
        v-if="showUploadModal"
        class="modal fade show"
        style="display: block"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Dokument hochladen</h5>
              <button
                type="button"
                class="btn-close"
                @click="closeUploadModal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="documentType" class="form-label">Dokumenttyp</label>
                <select
                  id="documentType"
                  v-model="selectedDocumentType"
                  class="form-select"
                >
                  <option value="">Bitte auswählen</option>
                  <option
                    v-if="
                      selectedAbsence &&
                      selectedAbsence.sickConfirmationFileId === null
                    "
                    value="CAREGIVER_SICK_DOC"
                  >
                    Abwesenheitsbestätigung
                  </option>
                  <option
                    v-if="
                      selectedAbsence &&
                      selectedAbsence.healthConfirmationFileId === null
                    "
                    value="CAREGIVER_HEALTH_DOC"
                  >
                    Anwesenheitsbestätigung
                  </option>
                </select>
              </div>

              <input
                type="file"
                ref="fileInput"
                id="fileUpload"
                @change="handleFileChange"
                class="d-none"
                accept="image/*,.pdf"
              />

              <div
                @drop.prevent="handleFileDrop"
                @dragover.prevent
                @click="onDropZoneClick"
                class="mt-4 p-4 border border-2 border-dashed rounded text-center cursor-pointer"
              >
                Klicken Sie hier oder ziehen Sie Ihre Datei zum Hochladen
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="closeUploadModal">
                Schließen
              </button>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show"></div>
      </div>

      <footer class="footer mt-4">
        <div class="footer-content text-center text-muted">
          <p>&copy; Alle Rechte vorbehalten.</p>
        </div>
      </footer>
    </div>
  </layout-div>
</template>

<script>
import axios from "axios";
import LayoutDiv from "../LayoutDiv.vue";
import NavBarComponent from "../NavBarComponent.vue";
import "@/assets/css/absence.css";

export default {
  name: "AbsencePage",
  components: {
    LayoutDiv,
    NavBarComponent,
  },
  data() {
    return {
      user: {},
      absencesChildren: [],
      absencesCaregiver: [],
      userAbsences: [],
      showAbsenceModal: false,
      showUploadModal: false,
      comment: "",
      absenceType: "",
      selectedChild: "",
      absenceReason: "",
      selectedAbsence: null,
      selectedDocumentType: "",
      file: null,
      children: [],
      errorMessage: "",
      absenceDateStart: "",
      absenceDateEnd: "",
      allDay: false,
      startTime: "06:00:00",
      endTime: "23:45:00",
      timeOptions: this.generateTimeOptions(),
      otpIntervalId: null,
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    };
  },
  watch: {
    absenceType(newType) {
      if (newType === "child") {
        this.absenceReason = "";
      }
    },
  },
  computed: {
    isValidForm() {
      if (this.absenceType === "child") {
        return this.absenceDateStart && this.selectedChild;
      } else {
        const isDateValid =
          this.absenceDateStart &&
          this.absenceDateEnd &&
          this.absenceDateStart <= this.absenceDateEnd;
        const isTimeValid =
          this.startTime && this.endTime && this.startTime <= this.endTime;
        return isDateValid && isTimeValid && this.absenceReason;
      }
    },
  },
  created() {
    this.initializeDashboard();
    this.otpIntervalId = setInterval(this.checkOTPStatus, 60000);
  },
  beforeUnmount() {
    if (this.otpIntervalId) {
      clearInterval(this.otpIntervalId);
    }
  },
  methods: {
    handleIosClick() {
      if (this.file) {
        this.handleUpload();
      } else {
        alert("Bitte wählen Sie zuerst eine Datei aus.");
      }
    },
    convertToHHMM(time) {
      const [hours, minutes] = time.split(":");
      return `${hours}:${minutes}`;
    },
    async checkOTPStatus() {
      try {
        const response = await axios.get(
          `/users/check-otp-status/${this.userId}`,
          { withCredentials: true }
        );
        if (response.data && response.data.success === false) {
          console.warn("OTP ist abgelaufen. Benutzer wird ausgeloggt.");
          this.logoutAction();
        }
      } catch (error) {
        console.error("Fehler beim Abrufen des OTP-Status:", error);
      }
    },
    setAllDayTimes() {
      if (this.allDay) {
        this.startTime = "06:00:00";
        this.endTime = "23:45:00";
      } else {
        this.startTime = "06:00:00";
        this.endTime = "23:45:00";
      }
    },
    generateTimeOptions() {
      const times = [];
      let start = new Date();
      start.setHours(6, 0, 0);
      let end = new Date();
      end.setHours(24, 0, 0);

      while (start <= end) {
        times.push(this.formatTime(start));
        start.setMinutes(start.getMinutes() + 15);
      }

      return times;
    },
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    async initializeDashboard() {
      try {
        await this.getUserData();
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    },
    async getUserData() {
      try {
        const response = await axios.get("/users/current", {
          withCredentials: true,
        });
        this.userId = response.data.id;

        const today = new Date();
        const formattedDate =
          today.getFullYear() +
          ("0" + (today.getMonth() + 1)).slice(-2) +
          ("0" + today.getDate()).slice(-2);

        const userDataResponse = await axios.get(
          `/users/get_user_data/${this.userId}/${formattedDate}`,
          { withCredentials: true }
        );
        if (userDataResponse && userDataResponse.data) {
          this.user = userDataResponse.data.user || {};
          this.combinedData = userDataResponse.data.combinedData || [];
          this.absencesChildren = userDataResponse.data.absences_children || [];
          this.absencesCaregiver =
            userDataResponse.data.absences_caregiver || [];
          this.userAbsences = userDataResponse.data.user_absences || [];
          this.children = this.combinedData.map((item) => item.children_name);
        } else {
          console.error("Unexpected response format:", userDataResponse);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.$router.push("/");
      }
    },
    getChildName(id) {
      const child = this.combinedData.find(
        (item) => item.fk_children_id === id
      );
      return child ? child.children_name : "Unbekannt";
    },
    formatDate(dateStr) {
      return (
        dateStr.slice(6, 8) +
        "." +
        dateStr.slice(4, 6) +
        "." +
        dateStr.slice(0, 4)
      );
    },
    async reportAbsence() {
      try {
        if (!this.isValidForm) {
          if (
            !this.absenceDateStart ||
            !this.absenceDateEnd ||
            this.absenceDateStart > this.absenceDateEnd
          ) {
            this.errorMessage =
              "Das Startdatum darf nicht größer als das Enddatum sein.";
          } else if (this.startTime > this.endTime) {
            this.errorMessage =
              "Die Startzeit darf nicht größer als die Endzeit sein.";
          }
          alert(this.errorMessage);
          return;
        }

        const formattedStartDate = this.absenceDateStart.replace(/-/g, "");
        const formattedEndDate = this.absenceDateEnd.replace(/-/g, "");

        const payload = {
          force_approve: false,
          absence_start: formattedStartDate,
          absence_type: this.absenceReason || "Abwesend",
          type: this.absenceType === "child" ? "CHILDREN" : "CAREGIVER",
          comment: this.comment || null,
        };

        if (this.absenceType === "child") {
          const startDate = new Date(this.absenceDateStart);
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 14);

          const formattedEndDateForChild = endDate
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "");
          payload.absence_end = formattedEndDateForChild;
          payload.absence_time_start = "06:00:00";
          payload.absence_time_end = "23:45:00";
        } else {
          payload.absence_end = formattedEndDate;
          payload.absence_time_start = this.startTime;
          payload.absence_time_end = this.endTime;
        }

        let id;
        if (this.absenceType === "child") {
          const childRecord = this.combinedData.find(
            (item) => item.children_name === this.selectedChild
          );
          if (childRecord && childRecord.fk_children_id) {
            id = childRecord.fk_children_id;
          } else {
            console.error("Kein passender Kind-Datensatz gefunden.");
            return;
          }
        } else if (this.absenceType === "caregiver") {
          id = this.userId;
        }

        await axios.put(`/users/insert_absence/${id}`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        this.showAbsenceModal = false;
        await this.initializeDashboard();

        alert("Abwesenheit erfolgreich gemeldet!");
      } catch (error) {
        console.error("Fehler beim Melden der Abwesenheit:", error);
        alert(
          "Fehler beim Melden der Abwesenheit. Bitte versuchen Sie es erneut."
        );
      }
    },
    logoutAction() {
      localStorage.clear();
      axios
        .post("/users/logout", {}, { withCredentials: true })
        .then(() => {
          this.clearCookies();
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    },
    clearCookies() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    },
    openUploadModal(absence) {
      if (
        absence.sickConfirmationFileId !== null &&
        absence.healthConfirmationFileId !== null
      ) {
        alert(
          "Beide Dokumente wurden bereits hochgeladen. Kein weiteres Hochladen erforderlich."
        );
        return;
      }

      this.selectedAbsence = absence;
      this.showUploadModal = true;

      if (
        absence.healthConfirmationFileId !== null &&
        absence.sickConfirmationFileId !== null
      ) {
        this.selectedDocumentType = "";
      } else if (
        absence.sickConfirmationFileId !== null &&
        absence.healthConfirmationFileId === null
      ) {
        this.selectedDocumentType = "CAREGIVER_HEALTH_DOC";
      } else if (
        absence.sickConfirmationFileId === null &&
        absence.healthConfirmationFileId === null
      ) {
        this.selectedDocumentType = "CAREGIVER_SICK_DOC";
      } else {
        this.selectedDocumentType = "";
      }
    },
    closeUploadModal() {
      this.showUploadModal = false;
      this.selectedDocumentType = "";
      this.file = null;
    },
    validateSelection() {
      if (this.selectedAbsence) {
        if (
          this.selectedAbsence.healthConfirmationFileId !== null ||
          (this.selectedAbsence.sickConfirmationFileId !== null &&
            this.selectedAbsence.healthConfirmationFileId !== null)
        ) {
          this.selectedDocumentType = "";
        } else if (this.selectedAbsence.sickConfirmationFileId !== null) {
          this.selectedDocumentType = "CAREGIVER_HEALTH_DOC";
        } else {
          this.selectedDocumentType = "CAREGIVER_SICK_DOC";
        }
      }
    },
    handleFileChange(event) {
      if (
        this.selectedAbsence &&
        this.selectedAbsence.healthConfirmationFileId !== null
      ) {
        alert(
          "Das Hochladen ist nicht erlaubt, da bereits eine Anwesenheitsbestätigung vorliegt."
        );
        return;
      }
      this.file = event.target.files[0];
      if (this.isIOS && !this.file) {
        return;
      }
      this.handleUpload();
    },
    handleFileDrop(event) {
      if (
        this.selectedAbsence &&
        this.selectedAbsence.healthConfirmationFileId !== null
      ) {
        alert(
          "Das Hochladen ist nicht erlaubt, da bereits eine Anwesenheitsbestätigung vorliegt."
        );
        return;
      }
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.handleUpload();
      }
    },
    onDropZoneClick() {
      if (
        this.selectedAbsence &&
        this.selectedAbsence.healthConfirmationFileId !== null
      ) {
        alert(
          "Das Hochladen ist nicht erlaubt, da bereits eine Genesungsbestätigung vorliegt."
        );
        return;
      }

      setTimeout(() => {
        this.$refs.fileInput.click();
      }, 100);
    },
    // Update the handleUpload method to show detailed error messages
    async handleUpload() {
      if (!this.file) {
        alert("Bitte wählen Sie eine Datei aus.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append("File", this.file);

        const absenceId = this.selectedAbsence.id;
        const type = this.selectedDocumentType;

        if (!absenceId || !type) {
          alert("Fehler: Abwesenheits-ID oder Dokumenttyp fehlt.");
          return;
        }

        console.log("Uploading file:", this.file.name, "type:", this.file.type);
        console.log("To absence ID:", absenceId, "doc type:", type);

        const response = await axios.post(
          `/users/upload_documents/${absenceId}/${type}`,
          formData,
          { withCredentials: true }
        );

        console.log("Upload response:", response.data);

        if (response.data.success) {
          alert("Dokument erfolgreich hochgeladen!");
          this.closeUploadModal();
          await this.initializeDashboard(); // Besser als window.location.reload()
        } else {
          alert(
            "Fehler beim Hochladen: " +
              (response.data.message || "Unbekannter Fehler")
          );
        }
      } catch (error) {
        console.error("Upload error details:", error);
        alert(
          "Fehler beim Hochladen: " + (error.message || "Unbekannter Fehler")
        );
      }
    },
  },
};
</script>
