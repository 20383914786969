import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";

// Bootstrap importieren
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Behalte deinen eigenen CSS-Stil bei
import "./assets/css/main.css";

import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "./components/pages/LoginPage";
import DashboardPage from "./components/pages/DashboardPage";
import BookingPage from "./components/pages/BookingPage";
import AbsencePage from "./components/pages/AbsencePage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faCalendarCheck,
  faUserSlash,
  faSignOutAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faCalendarCheck,
  faUserSlash,
  faSignOutAlt,
  faClipboardList
);

// Bootstrap Icons werden bereits importiert
import "bootstrap-icons/font/bootstrap-icons.css";

import { CalendarView } from "vue-simple-calendar";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(function (config) {
  config.headers["x-api-key"] = process.env.VUE_APP_API_KEY;
  return config;
});

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: LoginPage },
    { path: "/dashboard", component: DashboardPage },
    { path: "/bookings", component: BookingPage },
    { path: "/absences", component: AbsencePage },
  ],
});

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("CalendarView", CalendarView);

app.use(router).mount("#app");
