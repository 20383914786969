<template>
  <nav class="navbar">
    <div class="nav-container">
      <!-- Füge eine spezifische Klasse für die Navigationslinks hinzu -->
      <ul class="nav-links">
        <li>
          <a href="/dashboard"
            ><font-awesome-icon :icon="['fas', 'home']" /> Dashboard</a
          >
        </li>
        <li>
          <a href="/bookings"
            ><font-awesome-icon :icon="['fas', 'calendar-check']" />
            Dienstplan</a
          >
        </li>
        <li>
          <a href="/absences"
            ><font-awesome-icon :icon="['fas', 'user-slash']" />
            Abwesenheiten</a
          >
        </li>
        <li>
          <a href="#" @click="$emit('logout')"
            ><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Logout</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import "@/assets/css/navbar.css";

export default {
  name: "NavBarComponent",
};
</script>
