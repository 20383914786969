<template>
  <layout-div>
    <div class="d-flex justify-content-center mt-5">
      <div class="modal-container">
        <div class="modal-header d-flex flex-column align-items-center">
          <img
            src="../../assets/sps/logo.png"
            alt="Logo"
            class="img-fluid mb-2"
            style="max-width: 150px; max-height: 100px"
          />
          <h5 class="fs-4 fw-bold" v-if="!isOtpStage">{{ APP_TITLE }}</h5>
          <h5 class="fs-4 fw-bold" v-else>OTP Verifizieren</h5>
        </div>
        <div class="modal-body">
          <div
            v-if="validationErrors.general"
            class="alert alert-danger text-center"
            role="alert"
          >
            {{ validationErrors.general }}
          </div>

          <div
            v-if="isOtpStage"
            class="alert alert-warning text-center"
            role="alert"
          >
            Ein Bestätigungscode(4 stellig) wurde an Ihre E-Mail-Adresse
            gesendet. Bitte überprüfen Sie Ihr Postfach und geben Sie den
            OTP-PIN unten ein.
          </div>

          <form v-if="!isOtpStage" @submit.prevent="loginAction">
            <div class="mb-4">
              <label for="username" class="section-heading">Benutzername</label>
              <input
                v-model="username"
                type="text"
                class="form-control input-field"
                id="username"
                name="username"
              />
            </div>
            <div class="mb-4">
              <label for="password" class="section-heading">Passwort</label>
              <div class="position-relative">
                <input
                  v-model="password"
                  :type="passwordFieldType"
                  class="form-control input-field pe-5"
                  id="password"
                  name="password"
                />
                <span
                  class="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                  @click="togglePasswordVisibility"
                >
                  <i
                    :class="
                      passwordFieldType === 'text'
                        ? 'bi bi-eye-slash'
                        : 'bi bi-eye'
                    "
                    style="font-size: 1.2rem; color: black"
                  ></i>
                </span>
              </div>
            </div>
            <div class="d-flex flex-column gap-3">
              <button :disabled="isSubmitting" type="submit" class="btn button">
                Anmelden
              </button>
            </div>
          </form>

          <form v-else @submit.prevent="verifyOtpAction">
            <div class="mb-4">
              <label for="otp" class="section-heading">OTP-PIN</label>
              <input
                v-model="otp"
                type="text"
                class="form-control input-field"
                id="otp"
                name="otp"
                @input="updateButtonState"
              />
            </div>
            <div class="d-flex flex-column gap-3">
              <button
                style="margin-right: 5px"
                :disabled="!isOtpValid"
                type="submit"
                class="btn button"
              >
                OTP Verifizieren
              </button>
              <button
                type="button"
                class="btn button-secondary"
                @click="cancelOtpStage"
              >
                Abbrechen
              </button>
            </div>
          </form>
          <br />

          <footer
            class="bg-light p-3 text-center"
            style="font-size: 0.7em; color: #4a4a4a; border-top: 1px solid #ddd"
          >
            {{ VUE_APP_FOOTER_TEXT }}
          </footer>
        </div>
      </div>
    </div>
  </layout-div>
</template>

<script>
import axios from "axios";
import LayoutDiv from "../LayoutDiv.vue";
import "@/assets/css/login.css";

export default {
  name: "LoginPage",
  components: {
    LayoutDiv,
  },
  data() {
    return {
      APP_TITLE: process.env.VUE_APP_TITLE,
      VUE_APP_FOOTER_TEXT: process.env.VUE_APP_FOOTER_TEXT,
      username: "",
      password: "",
      otp: "",
      passwordFieldType: "text", // Standardmäßig sichtbar
      validationErrors: {},
      isSubmitting: false,
      isOtpStage: false,
      isOtpValid: false,
      userId: null,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "text" ? "password" : "text";
    },
    loginAction() {
      this.isSubmitting = true;
      const payload = {
        username: this.username,
        password: this.password,
      };

      axios
        .post("/users/authenticate", payload, { withCredentials: true })
        .then((response) => {
          if (response.data.userId) {
            this.userId = response.data.userId;
            this.isOtpStage = true;
          } else {
            this.validationErrors.general =
              "Es gab ein Problem bei der Anmeldung. Bitte versuche es später erneut.";
          }
          this.isSubmitting = false;
        })
        .catch((error) => {
          console.error(error);
          this.isSubmitting = false;
          this.validationErrors.general =
            error.response?.data?.message ||
            "Es ist ein unbekannter Fehler aufgetreten.";
        });
    },
    verifyOtpAction() {
      this.isSubmitting = true;
      const payload = {
        userId: this.userId,
        otp: this.otp,
      };

      axios
        .post("/users/verify-otp", payload, { withCredentials: true })
        .then((response) => {
          const token = response.data.token;

          localStorage.setItem("user_id", this.userId);
          document.cookie = `otp_token=${encodeURIComponent(
            token
          )}; path=/; SameSite=Strict; Secure; Max-Age=300; HttpOnly`;

          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.error("OTP verification error:", error);
          this.isSubmitting = false;
          this.validationErrors.general =
            error.response?.data?.message ||
            "Fehler bei der OTP-Verifizierung.";
        });
    },
    cancelOtpStage() {
      this.isOtpStage = false;
      this.userId = null;
      this.otp = "";
      this.isSubmitting = false;
      this.isOtpValid = false;
      this.validationErrors = {};
    },
    updateButtonState() {
      this.isOtpValid = this.otp.trim().length === 4;
    },
  },
};
</script>
<style></style>
