<!-- Template -->
<template>
  <div>
    <div class="content-container">
      <NavBarComponent @logout="logoutAction" />
    </div>

    <div class="content-container mt-3">
      <div class="legend">
        <div class="legend-item">
          <span class="legend-color bg-success bg-opacity-50"></span>
          <span>Bestätigte Stunden</span>
        </div>
        <div class="legend-item">
          <span class="legend-color bg-danger bg-opacity-50"></span>
          <span>Nicht bestätigte Stunden</span>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Ist-Leistungsstunden</th>
              <th>Soll-Leistungsstunden</th>
              <th>Soll-Anmeldestunden</th>
              <th>Pause (h)</th>
              <th>Abw. Leistungsstunden</th>
              <th>Abw. Anmeldestunden</th>
              <th>Anmerkung</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredData"
              :key="item.planned_date"
              :class="item.admin_confirmed ? 'table-success' : 'table-danger'"
            >
              <td>
                <b>{{ formatBookingDate(item.planned_date) }}</b>
              </td>
              <td>
                <b>{{ item.overall_working_hours }}</b>
              </td>
              <td>{{ item.service_working_hours_per_day }}</td>
              <td>{{ item.registered_working_hours_per_day }}</td>
              <td>{{ item.sum_pause }}</td>
              <td>{{ item.service_working_hours_per_day_diff }}</td>
              <td>{{ item.registered_working_hours_per_day_diff }}</td>
              <td>
                {{
                  item.type === "MANUAL_BOOKING_NORMAL"
                    ? "Normale Buchung"
                    : item.type === "ADMIN_BOOKING"
                    ? "Admin-Buchung"
                    : "Automatische Buchung"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer class="footer mt-4">
      <div class="footer-content text-center text-muted">
        <p>&copy; Alle Rechte vorbehalten.</p>
      </div>
    </footer>
  </div>
</template>

<!-- Script -->
<script>
import axios from "axios";
import NavBarComponent from "../NavBarComponent.vue";

export default {
  name: "DashboardPage",
  components: {
    NavBarComponent,
  },
  data() {
    return {
      user: {},
      combinedData: [],
      totalWorkingHours: 0,
      userId: null,
      otpIntervalId: null,
    };
  },
  computed: {
    filteredData() {
      return this.combinedData.filter((item) =>
        [
          "MANUAL_BOOKING_NORMAL",
          "ADMIN_BOOKING",
          "AUTO_BOOKING_NORMAL",
        ].includes(item.type)
      );
    },
  },
  created() {
    this.getUser();
    this.otpIntervalId = setInterval(this.checkOTPStatus, 60000);
  },
  beforeUnmount() {
    if (this.otpIntervalId) {
      clearInterval(this.otpIntervalId);
    }
  },
  methods: {
    async getUser() {
      try {
        const response = await axios.get("/users/current", {
          withCredentials: true,
        });
        this.userId = response.data.id;

        const userDataResponse = await axios.get(
          `/users/get_user_booking_data/${this.userId}`,
          { withCredentials: true }
        );

        if (userDataResponse && userDataResponse.data) {
          this.user = userDataResponse.data.user || {};
          this.combinedData = userDataResponse.data.bookingData || [];
          this.totalWorkingHours = this.combinedData.reduce(
            (total, item) =>
              total + parseFloat(item.overall_working_hours || 0),
            0
          );
        } else {
          console.error("Unexpected response format:", userDataResponse);
        }
      } catch (error) {
        console.error("Error fetching combined data:", error);
        this.$router.push("/");
      }
    },
    async checkOTPStatus() {
      try {
        const response = await axios.get(
          `/users/check-otp-status/${this.userId}`,
          { withCredentials: true }
        );
        if (response.data && response.data.success === false) {
          console.warn("OTP ist abgelaufen. Benutzer wird ausgeloggt.");
          this.logoutAction();
        }
      } catch (error) {
        console.error("Fehler beim Abrufen des OTP-Status:", error);
      }
    },
    formatBookingDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(
        dateStr.slice(0, 4),
        dateStr.slice(4, 6) - 1,
        dateStr.slice(6, 8)
      );
      if (isNaN(date.getTime())) return "";
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    },
    logoutAction() {
      localStorage.clear();
      axios
        .post("/users/logout", {}, { withCredentials: true })
        .then(() => {
          this.clearCookies();
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    },
    clearCookies() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    },
  },
};
</script>
